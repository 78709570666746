import { Box, SxProps } from "@mui/material";
import { CabTextInput } from "@CabComponents/CabTextInput";
import { CabIcon } from "@CabComponents/CabIcon";
import { IoSearch } from "react-icons/io5";
import { Controller, useForm } from "react-hook-form";


export type SearchForm = {
  search: string;
};

const SearchInputForm = ({ defaultSearch, onSearch, sx }: {
  defaultSearch?: string; onSearch: ({ search }: SearchForm) => void, sx?: SxProps
}) => {
  const { control, handleSubmit } = useForm<SearchForm>({ defaultValues: { search: defaultSearch || '' } });

  return (
    <Box component="form" onSubmit={handleSubmit(onSearch)} sx={sx}>
      <Controller control={control} name="search" render={({ field: { ref, ...field }}) => (
        <CabTextInput
          {...field}
          inputRef={ref}
          placeholder="Search Connections"
          size="xsmall"
          endIcon={<CabIcon Icon={IoSearch} />}
          fullWidth
        />
      )} />
    </Box>
  );
};


export default SearchInputForm;
